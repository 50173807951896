@font-face {
    font-family: 'FiraSans';
    src: local('FiraSans'), url(../fonts/Poppins-Regular.ttf) format('truetype');
}

body {
    margin: 0;
    font-family: Poppins, sans-serif;
}

.moving-image {
    background-size: 120% auto;
    background-repeat: no-repeat;
    margin: 4px;
}

@keyframes animatedBackground {
    0% {
        background-position: 50% 50%
    }
    20% {
        background-position: 40% 50%
    }
    40% {
        background-position: 50% 40%
    }
    60% {
        background-position: 40% 40%
    }
    80% {
        background-position: 60% 40%
    }
    100% {
        background-position: 50% 50%
    }
}
